<template>
  <div class="main-page">
    <div class="container">
      <aside>
        <app-bar
          title="Pakai Promo"
          :previousLink="generateLink"
        />
      </aside>

      <div class="row">
        <div class="col-12 col-md-5">
          <div class="form-group row mb-3 align-items-center">
            <label>Kode Promo</label>
            <div class="col-8">
              <div class="input-group">
                <Field
                  type="text"
                  class="form-control"
                  v-model="tempVoucher"
                  name="kode_promo"
                  placeholder="Masukkan Kode Promo"
                />
                <span class="input-group-text bg-grayscale border-0">
                  <div class="show-password" type="button" @click="resetVoucher" :class="{'opacity-0':!tempVoucher}">
                      <i class="ri-close-circle-fill fs-24"></i>
                  </div>
                </span>
              </div>
            </div>
            <div class="col-4">
              <button
                class="btn btn-primary w-100 py-1"
                :disabled="!tempVoucher"
                @click="useVoucher"
              >
                <span class="fw-600 fs-12">Pakai</span>
              </button>
            </div>
          </div>

          <!-- <div
            :class="selectedVoucher ? 'pb-5 pb-md-3 mb-5 mb-md-0' : 'pb-3 mb-md-0'">
            <div class="promo mb-3">
              <div class="promo-header mb-3">
                <div class="promo-title fw-700 fs-14 color-neutral-900">Promo Tersedia</div>
                <label for="promoLabel" class="fw-400 fs-12 color-neutral-600">Pilih promo yang tersedia</label>
              </div>
              <div class="promo-body d-flex flex-column gap-3">
                <div
                  class="promo-item d-flex align-items-center justify-content-between border"
                  :class="{ active: selectedVoucher === 1 }">
                  <router-link
                    class="promo-item-group d-flex align-items-center gap-2"
                    :to="{ name: 'UseVoucherDetail' }">
                    <div class="promo-item-group-image">
                      <img src="@/assets/icon/drugs.svg" alt="Icon">
                    </div>
                    <div class="">
                      <div class="promo-item-group-title fw-700 fs-14 color-neutral-900 mb-1">Diskon Rp. 100.000</div>
                      <p class="promo-item-group-expired fw-400 fs-12 color-neutral-600 mb-0">Berakhir 3 hari lagi</p>
                    </div>
                  </router-link>
                  <input
                    class="input-promo"
                    type="radio"
                    name="input-promo"
                    @click="selectedVoucher = 1">
                </div>
                <div
                  class="promo-item d-flex align-items-center justify-content-between border"
                  :class="{ active: selectedVoucher === 2 }">
                  <div class="promo-item-group d-flex align-items-center gap-2">
                    <div class="promo-item-group-image">
                      <img src="@/assets/icon/drugs.svg" alt="Icon">
                    </div>
                    <div class="">
                      <div class="promo-item-group-title fw-700 fs-14 color-neutral-900 mb-1">Diskon Rp. 100.000</div>
                      <p class="promo-item-group-expired fw-400 fs-12 color-neutral-600 mb-0">Berakhir 3 hari lagi</p>
                    </div>
                  </div>
                  <input
                    class="input-promo"
                    type="radio"
                    name="input-promo"
                    @click="selectedVoucher = 2">
                </div>
              </div>
            </div>
            <div class="promo">
              <div class="promo-header mb-3">
                <div class="promo-title fw-700 fs-14 color-neutral-900">Promo Dari Membership</div>
                <label for="promoLabel" class="fw-400 fs-12 color-neutral-600">Promo yang didapat dari membership</label>
              </div>
              <div class="promo-body d-flex flex-column gap-3">
                <div
                  class="promo-item d-flex align-items-center justify-content-between border"
                  :class="{ active: selectedVoucher === 3 }">
                  <div class="promo-item-group d-flex align-items-center gap-2">
                    <div class="promo-item-group-image">
                      <img src="@/assets/icon/drugs.svg" alt="Icon">
                    </div>
                    <div class="">
                      <div class="promo-item-group-title fw-700 fs-14 color-neutral-900 mb-1">Diskon Rp. 100.000</div>
                      <p class="promo-item-group-expired fw-400 fs-12 color-neutral-600 mb-0">Berakhir 3 hari lagi</p>
                    </div>
                  </div>
                  <input
                    class="input-promo"
                    type="radio"
                    name="input-promo"
                    @click="selectedVoucher = 3">
                </div>
                <div
                  class="promo-item d-flex align-items-center justify-content-between border"
                  :class="{ active: selectedVoucher === 4 }">
                  <div class="promo-item-group d-flex align-items-center gap-2">
                    <div class="promo-item-group-image">
                      <img src="@/assets/icon/drugs.svg" alt="Icon">
                    </div>
                    <div class="">
                      <div class="promo-item-group-title fw-700 fs-14 color-neutral-900 mb-1">Diskon Rp. 100.000</div>
                      <p class="promo-item-group-expired fw-400 fs-12 color-neutral-600 mb-0">Berakhir 3 hari lagi</p>
                    </div>
                  </div>
                  <input
                    class="input-promo"
                    type="radio"
                    name="input-promo"
                    @click="selectedVoucher = 4">
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-5">
          <div v-if="selectedVoucher" class="bottom-bar p-3 p-md-0 mb-md-3">
            <div class="pay d-flex align-items-center justify-content-between">
              <div class="pay-info d-flex flex-column">
                <span class="pay-label fw-400 color-neutral-600 mb-1">Kamu bisa hemat</span>
                <span class="pay-total fw-700 color-neutral-900">Rp. 356.000</span>
              </div>
              <router-link
                :to="{ name: 'Checkout' }"
                class="btn btn-orange btn-pay ms-1 fw-600 fs-14 w-100 py-2 text-white
                  d-flex justify-content-center align-items-center gap-1"
              >
                Pakai Promo
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, computed } from 'vue'
import AppBar from '@/components/AppBar.vue'
import { Field } from 'vee-validate'
import { showNotif } from '@/core/utility'
import { cart, countVoucher, clearVoucher, voucherTemp, isErrorVoucher } from '@/core/cart'
import { useRoute, useRouter } from 'vue-router'
import { user } from '@/core/auth'

export default {
  components: {
    Field,
    AppBar
  },
  setup () {
    const selectedVoucher = ref(null)
    const route = useRoute()
    const router = useRouter()
    const tempVoucher = ref('')

    const generateLink = computed(() => {
      if (route.query.from && route.query.from === 'checkout') {
        return {
          name: 'Checkout'
        }
      }
      return {
        name: 'Keranjang'
      }
    })

    onMounted(() => {
      countVoucher() // Recheck + recount voucher
    })

    const useVoucher = async () => {
      voucherTemp.value = tempVoucher.value // harus ditaruh variable lain biar pas diback gak ngikut
      cart.value.kode_voucher = voucherTemp.value

      const countingVoucher = await countVoucher()

      if (countingVoucher) {
        // clear dulu biar gak ke diskon dobel
        await clearVoucher()
        router.push({
          name: 'Checkout'
        })
      } else {
        // check error voucher pengguna baru
        if (parseInt(user.value.pasien_baru)) {
          if (isErrorVoucher.voucherDouble.status) {
            showNotif({
              status: 'error',
              message: isErrorVoucher.voucherDouble.message
            })
          } else {
            showNotif({
              status: 'error',
              message: isErrorVoucher.voucherPenggunaBaru.message
            })
          }

          // reset status
          isErrorVoucher.voucherDouble.status = false
          isErrorVoucher.voucherDouble.message = ''
          isErrorVoucher.voucherPenggunaBaru.status = false
          isErrorVoucher.voucherPenggunaBaru.message = ''
        }
      }
    }

    const resetVoucher = async () => {
      voucherTemp.value = ''
      tempVoucher.value = ''
      await clearVoucher()
    }

    return {
      countVoucher,
      resetVoucher,
      voucherTemp,
      selectedVoucher,
      generateLink,
      useVoucher,
      tempVoucher
    }
  }
}
</script>

<style lang="scss" scoped>
  .form-control.is-invalid, .input-group-text.is-invalid {
    box-shadow: inset 0px -1px 0px #E21F39;
  }

  .form-control::placeholder {
    color: #CBD5E0;
  }

  .form-control, .input-group-text {
    background: none;
    border-radius: 0;
    box-shadow: inset 0px -1px 0px rgba(242, 242, 242, 0.8);
    padding: 8px 4px 8px 0px;
    font-size: 18px;
  }

  .promo {
    &-item {
      padding: 8px 12px;
      border-radius: 12px;

      .input-promo {
        width: 24px;
        height: 24px;

        &-label:active {
          color: #4A5568;
        }
      }

      .input-promo:checked {
        accent-color: #007A98;
      }

      &.active {
        background: #EBFFFD;
        border: 1px solid #007A98 !important;
      }
    }
  }

  .bottom-bar {
    @media (max-width: 520px) {
      background-color: white;
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      border-top: 1px solid rgb(240, 240, 240);
    }

    .pay {
      &-label {
        font-size: 12px;
        line-height: 14px;
      }

      &-total {
        font-size: 14px;
        line-height: 24px;
      }

      .btn-pay {
        width: 169px !important;
      }
    }
  }
</style>
